text .form-control {
    height: 36px !important;
}

.form-control {
    border-radius: 0.2rem !important;
    color: #3F4254 !important;
    background-color: #ffffff !important;
    background-clip: padding-box !important;
    border: 1px solid #9e9e9e !important;
}

.c-color-white {
    color: white;
}

.c-color-antiquewhite {
    color: antiquewhite;
}

.c-color-purple {
    color: #625185;
}

.c-color-navy {
    color: #1C355C;
}

.c-color-blue {
    color: #5e81ac;
}

.c-color-darkgray {
    color: #4C566A;
}

.c-color-darkgreen {
    color: #517C76;
}

.c-color-lightgreen {
    color: #7AA9A3;
}

.c-color-green {
    color: #7FA366;
}

.c-color-red {
    color: #BF616A;
}

.c-color-orange {
    color: #F09000;
}

.c-color-royalblue {
    color: #0079A2;
}

.c-color-pink {
    color: #D08770;
}

.c-abtn {
    cursor: pointer;
}

.c-abtn-stop {
    cursor: not-allowed;
}

.c-icon-button {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    outline: none;
    border: 0;
    background: transparent;
}

.c-container-100 {
    width: 100%;
    margin: 2rem;
}

.c-icon-button {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    outline: none;
    border: 0;
    background: transparent;
}

.c-timecard-bg {
    background-color: #bfcedc
}

.c-timecard-bg-alt {
    background-color: darkseagreen
}

.c-timecard-day-grid {
    border-top: 1px solid silver;
    border-left: 1px solid silver;
    border-right: 1px solid silver;
    border-bottom: 1px solid silver;
    margin-bottom: 10px;
    border-radius: 2px;
}


.c-timecard-parent-div {
    display: inline-block;
    position: relative;
    overflow: visible;
}

.c-timecard-nested-control {
    position: absolute;
    top: 12px;
    right: 12px;
}

.c-timecard-nested-control-2 {
    position: absolute;
    top: 9px;
    right: 5px;
}

.c-timecard-bl-silver {
    border-left: 1px solid silver;
}

.c-timecard-br-silver {
    border-right: 1px solid silver;
}

.c-timecard-bb-silver {
    border-bottom: 1px solid silver;
}

.c-timecard-bt-silver {
    border-top: 1px solid silver;
}

.c-timecard-selected-type {
    background-color: #4ee71d47;
}

.c-row-bg {
    background-color:#0f195126
}

.c-row-bg-alt {
    background-color:darkseagreen
}

.c-row-day-grid {
    border-top: 1px solid silver;
    border-left: 1px solid silver;
    border-right: 1px solid silver;
    border-bottom: 1px solid silver;
    margin-bottom: 10px;
    border-radius: 2px;
}


.c-row-parent-div {
    display: inline-block;
    position: relative;
    overflow: visible;
}

.c-row-nested-control {
    position: absolute;
    top: 12px;
    right: 12px;
}

.c-row-nested-control-2 {
    position: absolute;
    top: 9px;
    right: 5px;
}

.c-row-bl-silver {
    border-left: 1px solid silver;    
}

.c-row-br-silver {
    border-right: 1px solid silver;    
}

.c-row-bb-silver {
    border-bottom: 1px solid silver;    
}

.c-row-bt-silver {
    border-top: 1px solid silver;    
}

.c-row-selected-type {
    background-color: #4ee71d47;
}
.c-no-decor {
    color: antiquewhite;
    text-decoration: none;
}
.c-label-1 {
    font-weight: 500;
    font-size: 1.1rem;
}

.c-label-2 {
    font-weight: 600;
    font-size: 1.2rem;
}

.c-label-3 {
    font-weight: 600;
    font-size: 1.3rem;
}

.c-label-4 {
    font-weight: 600;
    font-size: 1.4rem;
}

.c-label-4 {
    font-weight: 600;
    font-size: 1.5rem;
}

.p-card-title {
    font-size: 1.5em !important;
    font-weight: 500 !important;
    margin-bottom: .5em !important;
}

.p-card-header {
    background-color: #003049;
    font-size: 1.25rem;
    font-weight: 600;
    padding: 10px;
    color: antiquewhite;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
}
.p-menubar {
    padding: 0.75rem;
    background: transparent;
    color: rgba(0, 0, 0, 0.87);
    border: 0px;
    border-radius: 4px;
}
.c-container {
    display: block;
    position: relative;
    padding-left: 25px;
    padding-right: 25px;
    margin-bottom: 12px;
}

.ng-select.ng-select-single .ng-select-container {
    height: 33px;
}
.ng-select .ng-select-container {
    color: #333;
    background-color: #fff;
    border-radius: 0.2rem;
    border: 1px solid #9e9e9e;
    min-height: 33px;
    align-items: center;
}